<template>
  <div>
    <Environment v-if="environment != 'production'"/>
    <cmsHeader />
    <Toast />
    <ConfirmDialog />
    <div class="container">
      <div class="content">
      <div class="title">Trainers : List </div>
      Below is a list of Trainers sorted by date. A checkmark will indicate it as a Summer Session event (SS). 
      To add an event, click ADD EVENT. To edit an event, click the the title. The default trainer will be added 
      automatically (can be edited or deleted). To add a trainer to an event click the  button in the Trainers column. 
      To view the registrants list for an event click its  icon. To copy an event click the  icon. To delete an 
      event, click .
    </div>
      <div class="action-bar">
        <router-link to="/cms/trainer/create" class="btn-orange btn btn-default"> ADD TRAINER </router-link>
      </div>
    <table class="datatable" v-if="trainers">
      <th>Profile</th>
      <th>Name</th>
      <th>Title</th>
      <th>Location</th>
      <th width="110px">Actions</th>
      <tr v-if="!trainers">
        <td colspan="9" style="text-align:center;background-color:rgba(242, 233, 228, 0.2);">No Trainers Available.</td>
      </tr>
      <tr v-for="trainer in trainers" :key="trainer.id">
        <td class="profile-column"><img class="profile-image" name="trainerimage" :src="baseURL + trainerImagesPath + trainer.imageFilename"></td>
        <td>{{ trainer.name }}</td>
        <td>{{ trainer.title }}</td>
        <td>{{ trainer.home }}</td>
        <td>
          <button class="button" @click="editTrainer(trainer)">Edit</button> 
          <button class="button" @click="confirmDelete(trainer)">Delete</button>
        </td>
      </tr>
    </table>
    </div>
    <cmsFooter />
  </div>
</template>

<script>
// 3rd Party Libraries and Helpers
import { ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'

// Primevue
import ConfirmDialog from 'primevue/confirmdialog'
import { useConfirm } from 'primevue/useconfirm'

// Schema
//import Trainer from '../../../schemas/Trainer.js'

// Components
import cmsHeader from '../../../components/cms/cmsHeader.vue'
import cmsFooter from '../../../components/cms/cmsFooter.vue'

// API Services
import TrainingServices from '../../../services/TrainerServices.js'

export default {
  name: 'cmsTrainerList',
  components: {
    cmsHeader,
    cmsFooter,
    ConfirmDialog
  },
  setup() {
    const toast = useToast()
    const confirm = useConfirm()
    const router = useRouter()
    const baseURL = process.env.VUE_APP_API_SERVER
    const environment = process.env.VUE_APP_ENVIRONMENT
    const trainerImagesPath = process.env.VUE_APP_TRAINER_IMAGES
    const trainers = ref([])

    
    TrainingServices.getTrainingResources()
    .then((response) => {
      trainers.value = response.data
    })

    const editTrainer = (trainer) => {
      router.push({ name:"cmsTrainerEdit", query:{ tID: trainer.id}})
    }

    const deleteTrainer = (trainer) => {
      TrainingServices.deleteTrainingResource(trainer)
      .then((response) => {
        if(response.status != 200) {
          toast.add({severity: 'info', summary: 'Information', detail: response.message, life: 5000})
        } else {
          toast.add({severity: 'success', summary: 'Success', detail: "Trianer has been deleted!", life: 5000})
          trainers.value.splice(trainers.value.indexOf(trainer),1)
          TrainingServices.deleteImageFromFileSystem(trainer.imageFilename)
        }
      })
      .catch(() => {
        toast.add({severity: 'error', summary: 'Error', detail: 'Trainer could not be deleted', life: 5000})
      })
    }

    const confirmDelete = (trainer) => {
      confirm.require({
        message: `Do you want to delete trainer ${trainer.name}?`,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: () => {
          deleteTrainer(trainer)
        }
      })
    }

    return {
      toast,
      confirm,
      baseURL,
      environment,
      trainerImagesPath,
      trainers,
      editTrainer,
      deleteTrainer,
      confirmDelete
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
}
.title {
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 15px;
}
.content {
  margin-top: 10%;
  text-align: left;
}
.action-bar {
  display: grid;
  justify-content: right;
  margin-top: 5%;
}
.datatable {
  margin-top: 1%;
  margin-bottom: 15%;
}
tr:nth-child(2n) {
  background-color: rgba(242, 233, 228, 0.5);
}
th {
  text-align: center;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(201, 173, 167, 1);
  background-color: rgba(242, 233, 228, 1);
}
td {
  text-align: left;
  padding-right: 2px;
  padding-left: 5px;
  
}
.button {
  margin: 5px 4px 2.5px 0px;
}
.profile-image {
  width: 25px;
  border-color: Green;
  border-style: solid;
  border-width: 1px;
  border-radius: 75px;
  background-color: green;
}
.profile-column {
  width: 50px;
  text-align: center;
}
</style>
