<template>
  <div class="container-fluid blogbar"> </div>
  <div class="container-fluid">
  <!-- Static navbar -->
  <div class="col-xs-12">
      <nav class="navbar navbar-default">
      <div class="col-xs-12 col-md-12 col-lg-3">
              <a class="" href="<?=ROOT?>cms/entry.php" alt="Home" title="Home">
                <img class="img-responsive jgtoplogo" style="margin-top:10px;" src="\images/jacinta_logo_small1.png">
              </a>
      </div>
      <div class="donotprint">
      <div class="col-xs-12 col-md-12 col-lg-9">
          <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
          </div>
          <div id="navbar" class="navbar-collapse collapse">
            <ul class="nav navbar-nav" style="margin-top: 45px;">
          <li :class=" { 'active': currentRoute == '/cms' } "><router-link to="/cms" alt="Home" title="Home">Home</router-link></li>
          <li :class=" { 'active': currentRoute == '/cms/event/list' || currentRoute.substring(0,15) == '/cms/event/edit' } " ><router-link to="/cms/event/list" alt="Events" title="Events">Events</router-link></li>
          <li :class=" { 'active': currentRoute == '/cms/blog/list' || currentRoute.substring(0,14) == '/cms/blog/edit' } " ><router-link to="/cms/blog/list" alt="Resources" title="Resources">Blogs</router-link></li>
          <li :class=" { 'active': currentRoute == '/cms/trainers/list' || currentRoute.substring(0,18) == '/cms/trainers/edit' || currentRoute.substring(0,19) == '/cms/trainer/create' } " ><router-link to="/cms/trainers/list" alt="Resources" title="Resources">Training Resources</router-link></li>
          <!-- <li :class=" { 'active': route == '/cms/mailLog' } " ><router-link to="/cms/mailLog" alt="Mail Log" title="Mail Log">Mail Log</router-link></li> -->
          <li class="logout" @click="logoutNow"><a title="Log out now">Log Out</a></li>
          <li><a> ({{ username }}) </a></li>
            </ul>
          </div>
        </div>
        </div>
      </nav>
    </div>
  </div><!--container-->

  <div class="container-fluid quotecontainer donotprint">
        <div class="container quotetext"> 
          <span class="largetext">Content Management System</span>
        </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export default {
  data() {
    return {
      //route: this.$route.fullPath
    }
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const currentRoute = route.fullPath
    const username = store.state.user.user.username

    const logoutNow = () => {
      store.dispatch('logout').then(() => router.push({name: 'cmsLogin'}))
    }

    return {
      currentRoute,
      username,
      logoutNow
    }
  }
}
</script>

<style lang="scss" scoped>
.logout {
  cursor: pointer;
}
</style>