<template>
  <div class="container-fluid footer-bottom">
  <div class="row">
  <footer> 

  <div class="col-xs-12 col-sm-6 copyright">
  <span class="line">&copy;2021 Jacinta Gallant.</span> <span class="line"> All rights reserved.</span>
  </div>

  <div class="col-xs-12 col-sm-6 copyright" style="text-align: right;">
  <span class="line"> 
  Site Created by</span><span class="line">  <a  class="wsa-link" target="_blank"  href="http://wsadvantage.com/"> Web Site Advantage</a>.</span>

  </div>        


  </footer>
  </div><!--row-->
  </div><!--container-->
</template>